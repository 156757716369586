<template>
  <div class="app-container table-box">
    <el-row class="box search mt20" :gutter="10">
      <el-form
        :inline="true"
        :model="listQuery"
        class="w"
        status-icon
        style="text-align: left"
      >
        <el-form-item size="small">
          <el-input
            placeholder="请输入企业名称"
            v-model="listQuery.company_name"
            clearable
            class="input-with-select"
            @clear="onSearch"
          >
          </el-input>
        </el-form-item>
        <el-row class="box search" :gutter="10">
          <el-col :span="4">
            <el-form-item size="small">
              <el-select
                      clearable
                      v-model="provinceTemp"
                      placeholder="请选择省级"
                      @change="handleChange($event, 2)"
                      value-key="region_name"
                      :disabled="provinceDis"
              >
                <el-option
                        v-for="item in provinceOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="small">
              <el-select
                      clearable
                      v-model="cityTemp"
                      placeholder="请选择市级"
                      @change="handleChange($event, 3)"
                      value-key="region_name"
                      :disabled="cityDis"
              >
                <el-option
                        v-for="item in cityOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="small">
              <el-select
                      clearable
                      v-model="areaTemp"
                      placeholder="请选择区/县"
                      @change="handleChange($event, 4)"
                      value-key="region_name"
                      :disabled="areaDis"
              >
                <el-option
                        v-for="item in areaOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="small">
              <el-select
                      clearable
                      v-model="townTemp"
                      placeholder="请选择街道/镇"
                      @change="handleChange($event, 5)"
                      value-key="region_name"
              >
                <el-option
                        v-for="item in townOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="small">
              <el-select
                      clearable
                      v-model="villTemp"
                      placeholder="请选择居委会/村"
                      value-key="region_name"
                      @change="handleChange($event, 6)"
              >
                <el-option
                        v-for="item in villOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-form-item size="small">
            <el-button
                    type="primary"
                    @click="onSearch"
                    class="general_bgc general_border cfff"
            >查询</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
    </el-row>
    <el-table
      :data="list"
      v-loading="listLoading"
      border
      fit
      style="width: 100%"
    >
      <el-table-column label="区域名称" prop="name" align="center">
      </el-table-column>
      <el-table-column label="所属企业" prop="company_name" align="center">
      </el-table-column>
      <el-table-column label="区域地址" prop="address" align="center">
      </el-table-column>
      <el-table-column label="区域面积" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.area + "(" + scope.row.unit + ")" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="区域图片" align="center">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="primary"
            :disabled="scope.row.imgs.length == 0"
            @click="handleViewPic(scope.row.imgs)"
            >查看图片{{
              scope.row.imgs.length ? "(" + scope.row.imgs.length + "张)" : ""
            }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="联系人" align="center" prop="concat">
      </el-table-column>
      <el-table-column label="联系电话" align="center" prop="concat_phone">
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="created_time">
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="Number(listQuery.pager_openset)"
        layout="total, sizes, prev, pager, next, jumper"
        :total="listTotal"
      ></el-pagination>
    </div>
    <el-image-viewer
      style="z-index: 9999"
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="url"
    />
  </div>
</template>


<script>
import axios from "@/util/api";
import { local,session } from "@/util/util";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {
    ElImageViewer,
  },
  data() {
    return {
      listLoading: false,
      list: [],
      listQuery: {
        opt: "1",
        pager_offset: "0",
        pager_openset: "10",
        company_name: "",
        province: "",
        city: "",
        areas: "",
        town: "",
        vill: "",
      },
      showViewer: false,
      url: [],
      currentPage: 1,
      listTotal: 0,
      provinceTemp: "",
      cityTemp: "",
      areaTemp: "",
      townTemp: "",
      villTemp: "",
      province: "",
      city: "",
      area: "",
      town: "",
      vill: "",
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      townOptions: [],
      villOptions: [],
      userData: {},

    };
  },
  mounted() {
    this.userData = local.get("user");
    this.initArea()
  },
  computed: {
    provinceDis() {
      if (this.province) return true;
      else return false;
    },
    cityDis() {
      if (this.city) return true;
      else return false;
    },
    areaDis() {
      if (this.area) return true;
      else return false;
    },
  },
  methods: {
    onSearch() {
      this.initData();
    },
    get_count(contents) {
      return contents.length;
    },
    get_area_sum(contents) {
      let area = 0;
      contents.forEach((item) => {
        area += parseInt(item.area);
      });
      if (Number.isFinite(area)) return area;
      else return 0;
    },
    get_volume_sum(contents) {
      let volume = 0;
      contents.forEach((item) => {
        volume += parseInt(item.volume);
      });
      if (Number.isFinite(volume)) return volume;
      else return 0;
    },
    initData() {
      this.listLoading = true;
      axios
        .get("/pc/company-planting-area/list", this.listQuery)
        .then((response) => {
          let data = response.data.planting_list;
          data.forEach((item) => {
            if (item.img_url) {
              item.imgs = JSON.parse(item.img_url);
            }
          });
          this.list = data;
          //console.log("this.list: ", this.list);
          this.listTotal = response.data.planting_count;
          this.listLoading = false;
        });
    },
    initArea() {
      let url = "/pc/department/" + this.userData.user_id;
      //预加载监管部门区域
      axios.get(url).then((res) => {
        res = res.data.department;
        let i = 0;
        if (res.length > 0) {
          let level = res[0].level;
          for (let j = 0; j < res.length; j++) {
            if (level > res[j].level) i = j;
          }
          this.province = res[i].province;
          this.city = res[i].city;
          this.area = res[i].area;
          this.town = res[i].town;
          this.vill = res[i].vill;
          this.provinceTemp = res[i].province;
          this.cityTemp = res[i].city;
          this.areaTemp = res[i].area;
          this.townTemp = res[i].town;
          this.listQuery.province = res[i].province;
          this.listQuery.city = res[i].city;
          this.listQuery.areas = res[i].area;
          this.listQuery.town = res[i].town;
          this.userData.province = this.provinceTemp;
          this.userData.city = this.cityTemp;
          this.userData.area = this.areaTemp;
          this.handleUserLevel(this.userData);
          this.initData();
        }
      });
    },
    handleSizeChange(val) {
      this.listQuery.pager_openset = val;
      this.initData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.listQuery.pager_offset = String((val - 1) * 10);
      this.initData();
    },
    handleViewPic(imgs) {
      let url = imgs.map((item) => item.url);
      this.url = url;
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
    },
    async handleUserLevel(userData) {
      if (parseInt(userData.user_type) > 0) {
        this.provinceOptions = await this.fetchRegionData(1);
        let province = this.provinceOptions.find(
                (item) => item.region_name == userData.province
        );
        this.provinceTemp = province;
        if (
                parseInt(userData.user_type) >= 1 &&
                userData.province != null &&
                userData.province != ""
        ) {
          this.cityOptions = await this.fetchRegionData(
                  2,
                  province.region_code
          );
          let city = this.cityOptions.find(
                  (item) => item.region_name == userData.city
          );
          if (parseInt(userData.user_type) >= 2 && userData.city != null) {
            this.areaOptions = await this.fetchRegionData(3, city.region_code);
            let area = this.areaOptions.find(
                    (item) => item.region_name == userData.area
            );
            this.cityTemp = city;
            if (userData.area != null) {
              this.townOptions = await this.fetchRegionData(
                      4,
                      area.region_code
              );
              let town = this.townOptions.find(
                      (item) => item.region_name == userData.town
              );
              this.areaTemp = area;
              if (userData.town != null) {
                this.villOptions = await this.fetchRegionData(
                        5,
                        town.region_code
                );
                let vill = this.villOptions.find(
                        (item) => item.region_name == userData.vill
                );
                this.townTemp = town;
                if (userData.vill != null) {
                  this.villTemp = vill;
                }
              }
            }
          }
        }
      }
    },
    fetchRegionData(type, region_high_code) {
      let region_type = type.toString();
      return new Promise((resolve, reject) => {
        axios
                .get("/pc/region/all", { region_type, region_high_code })
                .then((response) => {
                  resolve(response.data.region_list);
                })
                .catch((err) => {
                  reject(err);
                });
      });
    },
    async handleChange(item, type) {
      let regionData;
      if (item.region_code == undefined) {
        regionData = "";
      } else {
        regionData = await this.fetchRegionData(type, item.region_code);
      }

      switch (type) {
        case 2:
          this.cityOptions = regionData;
          this.areaOptions = [];
          this.townOptions = [];
          this.villOptions = [];
          this.listQuery.province = item.region_name;
          this.listQuery.city = "";
          this.listQuery.areas = "";
          this.listQuery.town = "";
          this.listQuery.vill = "";
          this.cityTemp = "";
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 3:
          this.areaOptions = regionData;
          this.townOptions = [];
          this.villOptions = [];
          this.listQuery.city = item.region_name;
          this.listQuery.areas = "";
          this.listQuery.town = "";
          this.listQuery.vill = "";
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 4:
          this.townOptions = regionData;
          this.villOptions = [];
          this.listQuery.areas = item.region_name;
          this.listQuery.town = "";
          this.listQuery.vill = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 5:
          this.villOptions = regionData;
          this.listQuery.town = item.region_name;
          this.townTemp = item.region_name;
          this.listQuery.vill = "";
          this.villTemp = "";
          break;
        case 6:
          this.listQuery.vill = item.region_name;
          this.villTemp = item.region_name;
          break;
      }
      this.currentPage = 1;
      this.listQuery.pager_offset = "0";
      this.initData();
    },
  },
};
</script>

<style scoped>
.table-box {
  padding: 10px 20px;
}
</style>
